// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import {
  useCallback, useEffect, useRef,
} from 'react';
import AccountMultipleIcon from 'mdi-material-ui/AccountMultiple';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import PeopleIcon from '@mui/icons-material/People';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer/polyfilled';

// Internal Dependencies
import {
  AddButton,
  ConfirmRelationships,
  EnhancedCard,
  ZeroState,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetRelationships } from 'gql/queries';
import { useNavigate } from '@reach/router';
import PendingMemberAlert from 'components/shared/PendingMemberAlert';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import ChildrenUnderThirteen from './ChildrenUnderThirteen';
import RelationshipListItem from './RelationshipListItem';

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  '.listItem': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const FamilyCard = (): JSX.Element => {
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const { data } = useGetRelationships();
  const { self } = useSelfQuery();

  const isAdult = Boolean(Number(self?.role.id) === UserRoles.Adult);

  const { width = 1 } = useResizeObserver<HTMLElement>({ ref });

  const navigateToRelationshipsNew = useCallback(() => {
    navigate(`/${PATHS.RELATIONSHIPS}/new`);
  }, [navigate]);

  useEffect(() => {
    const locationHash = window.location.hash;

    // If the user was sent here to view the family relationships,
    //  then we focus that card
    if (locationHash === '#settings-family-card' && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  // Not using optional chaining here because of number operator
  const hasRelationships = !!(data && data.userRelationships.length > 0);
  const relationships = data?.userRelationships.filter((rel) => rel.isConfirmed) ?? [];
  const pendingRelationships = data?.userRelationships.filter((rel) => rel.isPending) ?? [];
  const sentPendingRelationships = pendingRelationships.filter(
    (rel) => rel.sender.id === self?.id,
  );
  const receivedPendingRelationships = pendingRelationships.filter(
    (rel) => rel.sender.id !== self?.id,
  );

  return (
    <StyledEnhancedCard
      id="settings-family-card"
      ref={ref}
    >
      <CardHeader
        action={(
          <AddButton
            label="Family"
            onClick={navigateToRelationshipsNew}
          />
        )}
        avatar={(
          <Avatar className="avatar">
            <AccountMultipleIcon aria-label="Family Settings" />
          </Avatar>
        )}
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            Family
          </Typography>
        )}
      />

      <CardContent>
        <Box mt={2}>
          {!hasRelationships && (
            <ZeroState
              Icon={PeopleIcon}
              maxTextWidth="80%"
              message="If you have family members in this organization, add them by clicking the button above."
              padding={0}
            />
          )}

          {!!relationships.length && (
            <List>
              {relationships.map((relationship) => (
                <RelationshipListItem
                  key={relationship.id}
                  relationship={relationship}
                />
              ))}
            </List>
          )}

          {!!sentPendingRelationships?.length && (
            <>
              {!!relationships?.length && (
                <Box mb={2}>
                  <Divider />
                </Box>
              )}

              <Typography
                component="h3"
                sx={{
                  fontSize: '1rem',
                  marginBottom: 2,
                }}
                variant="h6"
              >
                Pending ({sentPendingRelationships.length})
              </Typography>

              <PendingMemberAlert />

              <List>
                {sentPendingRelationships.map((relationship) => (
                  <RelationshipListItem
                    key={relationship.id}
                    relationship={relationship}
                  />
                ))}
              </List>
            </>
          )}

          {!!receivedPendingRelationships?.length && (
            <>
              {!!(relationships?.length || sentPendingRelationships.length) && (
                <Box my={1}>
                  <Divider />
                </Box>
              )}

              <Typography
                component="h3"
                sx={{
                  fontSize: '1rem',
                  marginBottom: 2,
                }}
                variant="h6"
              >
                Awaiting Your Response ({receivedPendingRelationships.length})
              </Typography>

              <ConfirmRelationships
                relationshipData={receivedPendingRelationships as GQL.IUserRelationship[]}
                width={width}
              />
            </>
          )}

          {isAdult && <ChildrenUnderThirteen />}
        </Box>
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default FamilyCard;
