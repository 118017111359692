// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

// Internal Dependencies
import { Address, EnhancedCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import {
  hasPermission,
  isDistrictAdmin,
  isDirector as selectIsDirector,
} from 'state/self/selectors';

// Local Typings
interface Props {
  user: GQL.ISelf | null;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['300'],
  },
  '.list-item': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.personal-info-content': {
    alignItems: 'flex-start',
    color: theme.palette.text.primary,
    display: 'flex',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  '.personal-info-content-actions': {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  '.personal-info-content-data': {
    minWidth: '30%',
  },
  '.subtitle': {
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
}));

// Component Definition
const ProfileCard = ({ user }: Props): JSX.Element | null => {
  const navigate = useNavigate();

  const isDirector = useSelector(selectIsDirector);
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const isDFA = useSelector(isDistrictAdmin);

  const handleViewSelf = useCallback(() => {
    if (isDFA) {
      return navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}`);
    }

    const userTypePath = isDirector
      ? PATHS.DIRECTORS
      : PATHS.MEMBERS;

    return navigate(`/${userTypePath}/${user?.id}`);
  }, [isDFA, isDirector, user?.id, navigate]);

  const handleEditSelf = useCallback(() => {
    if (isDFA) {
      return navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.PROFILE}/edit`);
    }

    const userTypePath = isDirector
      ? PATHS.DIRECTORS
      : PATHS.MEMBERS;

    return navigate(`/${userTypePath}/${user?.id}/edit`);
  }, [isDFA, isDirector, user?.id, navigate]);

  const handleEditEmailSignature = useCallback(() => {
    navigate(`/${PATHS.EMAIL_SIGNATURE}`);
  }, [navigate]);

  if (!user) {
    return null;
  }

  const {
    addressOne,
    addressTwo,
    city,
    phoneNumber,
    state,
    zipcode,
  } = user;

  return (
    <StyledEnhancedCard>
      <CardHeader
        avatar={(
          <Avatar className="avatar">
            <AccountCircleIcon aria-label="User profile settings" />
          </Avatar>
        )}
        subheader={`Information visible in this ${isDFA ? 'district' : 'organization'}`}
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            Member Profile
          </Typography>
        )}
      />
      <CardContent>
        <Typography
          className="subtitle"
          component="h6"
        >
          Personal Info
        </Typography>

        <div className="personal-info-content">
          <div className="personal-info-content-data">
            <Typography color="textPrimary">
              {getFullName(user)}
            </Typography>

            {user.title && (
              <Typography color="textPrimary">
                {user.title}
              </Typography>
            )}

            <Address
              addressOne={addressOne}
              addressTwo={addressTwo}
              city={city}
              phoneNumber={phoneNumber}
              state={state?.label ?? null}
              zipcode={zipcode}
            />
          </div>

          <div className="personal-info-content-actions">
            <Button
              aria-label="View Personal Info"
              color="primary"
              onClick={handleViewSelf}
              size="small"
              variant="outlined"
            >
              View
            </Button>

            <Button
              aria-label="Edit Personal Info"
              color="primary"
              onClick={handleEditSelf}
              size="small"
              variant="outlined"
            >
              Edit
            </Button>
          </div>
        </div>

        {canEmailMembers && !isDFA && (
          <>
            <Typography
              className="subtitle"
              component="h6"
            >
              Email Settings
            </Typography>

            <div className="personal-info-content">
              <div className="personal-info-content-data">
                <Typography color="textPrimary">
                  Signature
                </Typography>

                <Typography
                  color="textSecondary"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(user.emailSignature ?? 'No signature set'),
                  }}
                  variant="body2"
                />
              </div>

              <div className="personal-info-content-actions">
                <Button
                  aria-label="Edit Signature"
                  color="primary"
                  onClick={handleEditEmailSignature}
                  size="small"
                  variant="outlined"
                >
                  Edit Signature
                </Button>
              </div>
            </div>
          </>
        )}
      </CardContent>
    </StyledEnhancedCard>
  );
};

export default ProfileCard;
