// External Dependencies
import { ApolloError } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { UserRoles } from '@presto-assistant/api_types';
import { useCallback, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeAccountIcon from 'mdi-material-ui/BadgeAccount';
import Box from '@mui/material/Box';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  DynamicFormFields,
  EnhancedAlert,
  EnhancedContainer,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { ROLE_IDS } from 'utils/constants';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission } from 'state/self/selectors';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetMember, useGetSelf } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import SubscriberOnlyButton from 'components/shared/SubscriberOnlyButton';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import ContactInfoFields from '../../../shared/ContactInfoFields';
import DialogAddOrganizationRole from './DialogAddOrganizationRole';
import PeopleDangerZone from '../../../shared/PeopleDangerZone';
import ProfileInfoFields from '../../../shared/ProfileInfoFields';
import RoleFields from './RoleFields';

// Local Typings
interface Props {
  error?: ApolloError | null;
  initialValues?: Partial<StudentValues>;
  onSubmit?: (values: StudentValues) => Promise<void>;
  userId?: string;
  userOrganizationId?: string;
  validationSchema?: any;
}

// set grade as a string for the select component
// admin and roleId are deleted in the handleSubmit on update
export interface StudentValues extends Omit<GQL.ICreateUserInput, 'admin' | 'grade' | 'isEligible' | 'roleId'> {
  admin?: boolean;
  grade?: string;
  isEligible?: boolean;
  roleId?: string;
  successorOrganizationId?: string;
}

// Local Variables
const StyledSubscriberOnlyButton = styled(SubscriberOnlyButton)(({
  theme,
}) => ({
  '&:hover, &:focus': {
    backgroundColor: theme.palette.relationships.avatar,
  },
  color: theme.palette.mode === 'dark'
    ? theme.palette.grey[600]
    : theme.palette.stripeBlue['600'],
}));

const StyledGrid = styled(Grid)({
  '.MuiContainer-root': {
    width: CONTAINER_WIDTH,
  },
});

// Component Definition
const StudentForm = ({
  error,
  initialValues = {},
  onSubmit,
  userId,
  userOrganizationId,
  validationSchema,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canRemoveUsers = useSelector(hasPermission('users', 'delete'));

  const { data: selfData } = useGetSelf();

  const isSelf = selfData?.self?.id === userId;

  const defaultInitialValues: StudentValues = {
    ...(canEditUsers ? {
      grade: '',
      isEligible: true,
      primaryRoleId: '',
      secondaryRoleIds: [],
    } : {}),
    addressOne: '',
    addressTwo: '',
    admin: false,
    allergies: '',
    city: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    genderId: '',
    lastName: '',
    otherGenderLabel: '',
    phoneNumber: '',
    previousCampus: '',
    primarySpokenLanguageId: 1 /* English */,
    privateLessonTeacher: '',
    roleId: ROLE_IDS.STUDENT,
    secondaryEmail: '',
    secondaryPhoneNumber: '',
    shirtSize: '',
    stateId: '',
    studentInfo: {
      studentId: '',
    },
    zipcode: '',
  };

  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data: memberData,
  } = useGetMember(userId);

  const {
    isOpen: isAddOrganizationRoleDialogOpen,
    toggleIsOpen: toggleAddOrganizationRoleDialog,
  } = useIsOpen();

  const [
    showRoleEmptyState,
    setShowRoleEmptyState,
  ] = useState(!initialValues.primaryRoleId);

  const isEditing = Boolean(memberData?.user);

  const handleClickAddRole = () => {
    setShowRoleEmptyState(false);
  };

  const goBack = useCallback(() => {
    navigate(`/${PATHS.STUDENTS}`);
  }, [navigate]);

  const studentParams = useSelector(tableQueryParams('students'));

  const handleFormikSubmit = async (
    values: StudentValues,
    formikProps: FormikHelpers<StudentValues>,
  ) => {
    await onSubmit?.(values);

    formikProps.setSubmitting(false);
  };

  const errors = error ? normalizeFormErrors(error) : undefined;

  return (
    <>
      <Formik<StudentValues>
        enableReinitialize
        initialErrors={errors}
        initialValues={{
          ...((canUseDynamicFields && canEditUsers) ? applyDynamicFields(undefined) : {}),
          ...defaultInitialValues,
          ...initialValues,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            isSubmitting,
            values,
          } = formikProps;

          const { genderId, primaryRoleId, secondaryRoleIds } = values;

          const isFormTouched = Object.keys(formikProps.touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <StyledGrid container>
                <Container maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      icon={AccountCircleIcon}
                      title="Profile Info"
                    >
                      <ProfileInfoFields
                        genderId={genderId}
                        isEditing={!!userId}
                        isStudent
                      />
                    </ShowCard>
                  </Box>
                </Container>

                <Container maxWidth={false}>
                  <Box mb={2}>
                    <ShowCard
                      icon={CellphoneWirelessIcon}
                      title="Contact Info"
                    >
                      <ContactInfoFields />
                    </ShowCard>
                  </Box>
                </Container>

                {canEditUsers && (
                  <Container maxWidth={false}>
                    <DynamicFormFields
                      isAdmin={false}
                      item={memberData?.user ?? undefined}
                      organizationTypeId={undefined}
                      showCardProps={{
                        canEdit: isEditing,
                        readOnly: false,
                      }}
                      tableRef="members"
                    />
                  </Container>
                )}

                {canEditUsers && (
                  <Container maxWidth={false}>
                    <Box mb={2}>
                      <ShowCard
                        icon={BadgeAccountIcon}
                        title="Role"
                      >
                        <RoleFields
                          onClickAddRole={handleClickAddRole}
                          primaryRoleId={primaryRoleId}
                          secondaryRoleIds={secondaryRoleIds}
                          showRoleEmptyState={showRoleEmptyState}
                        />
                        <Box pt={2}>
                          <EnhancedAlert
                            action={(
                              <StyledSubscriberOnlyButton
                                key="create-role-button"
                                onClick={toggleAddOrganizationRoleDialog}
                                size="small"
                              >
                                Create Role
                              </StyledSubscriberOnlyButton>
                            )}
                          >
                            Need a new role? Create one!
                          </EnhancedAlert>
                        </Box>
                      </ShowCard>
                    </Box>
                  </Container>
                )}
              </StyledGrid>

              <EnhancedContainer>
                <FormRouteActions
                  context="Student"
                  isEditing={isEditing}
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={goBack}
                />
              </EnhancedContainer>
            </Form>
          );
        }}
      </Formik>

      {canRemoveUsers && userOrganizationId && !isSelf && (
        <EnhancedContainer>
          <PeopleDangerZone
            memberId={userId}
            memberRoleId={UserRoles.Student}
            organizationMembershipId={userOrganizationId}
            returnToOnSuccess={`/${PATHS.STUDENTS}${studentParams}`}
          />
        </EnhancedContainer>
      )}

      <DialogAddOrganizationRole
        isOpen={isAddOrganizationRoleDialogOpen}
        onClose={toggleAddOrganizationRoleDialog}
      />
    </>
  );
};

export default StudentForm;
