// External Dependencies
import { FC, useCallback } from 'react';
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import OrgListIcon from '@mui/icons-material/Apps';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  Address,
  EnhancedCard,
  Flex,
  Organization,
  OrganizationCode,
  SendWelcomeEmailListItem,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/editOrganizationDialog/actions';
import { useGetCcEmailList } from 'gql/queries';
import OrganizationLogo from 'components/shared/OrganizationLogo/OrganizationLogo';
import OrganizationLogoMenuItems from 'components/shared/OrganizationLogo/OrganizationLogoMenuItems';
import OrganizationLogoUploaderDialog from 'components/shared/OrganizationLogo/OrganizationLogoUploaderDialog';
import SubStatusBadge from 'components/shared/MainContentContainer/SideNav/SubStatusBadge';

// Local Dependencies
import { getScheduleTypeNameAndDescription } from 'pages/Settings/FinancialSettings';
import BeginEndOfYearProcessListItem from './BeginEndOfYearProcessListItem';
import DialogEditOrganization from './DialogEditOrganization';
import ManageInactiveUsersListItem from './ManageInactiveUsersListItem';

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.avatar': {
    backgroundColor: theme.palette.stripeBlue['500'],
  },
  '.listItem': {
    paddingBottom: 0,
    paddingTop: 0,
  },
  '.orgNameTitle': {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

// Component Definition
const OrganizationCard: FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleOpenEditDialog = () => {
    dispatch(open());
  };

  const handleNavigateToCommunicationSettings = useCallback(() => {
    navigate(`/${PATHS.COMMUNICATION_SETTINGS}`);
  }, [navigate]);

  const handleNavigateToFinancialSettings = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_SETTINGS}`);
  }, [navigate]);

  const { data: ccEmailListData } = useGetCcEmailList();

  const ccEmails = ccEmailListData?.ccEmailList;

  const canReadUsers = useSelector(hasPermission('users', 'read'));

  return (
    <>
      <Organization>
        {({ organization }) => {
          const {
            addressOne,
            addressTwo,
            canBeginEndOfYearProcess,
            city,
            code,
            currentSchoolYearEnding,
            entityType,
            financialStatementScheduleTypeId,
            label: orgName,
            logoUrl,
            phoneNumber,
            state,
            successorOrganization,
            zipcode,
          } = organization;

          const isCollegeOrUniversity = entityType.id === OrganizationEntityTypes.College
            .toString();

          return (
            <StyledEnhancedCard>
              <CardHeader
                action={(
                  <IconButton
                    aria-label="Edit Organization Information"
                    onClick={handleOpenEditDialog}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                )}
                avatar={(
                  <Avatar className="avatar">
                    <OrgListIcon aria-label="Organization settings" />
                  </Avatar>
                )}
                title={(
                  <Typography
                    component="h2"
                    variant="h6"
                  >
                    Organization
                  </Typography>
                )}
              />

              <CardContent>
                <Typography
                  className="orgNameTitle"
                  component="h3"
                >
                  Organization Info
                </Typography>

                <List>
                  <ListItem className="listItem">
                    <ListItemText
                      primary={orgName}
                      primaryTypographyProps={{
                        component: 'div',
                      }}
                      secondary={(
                        <Address
                          addressOne={addressOne}
                          addressTwo={addressTwo}
                          city={city}
                          phoneNumber={phoneNumber}
                          state={state.label}
                          zipcode={zipcode}
                        />
                      )}
                      secondaryTypographyProps={{
                        component: 'div',
                      }}
                    />
                  </ListItem>
                </List>
              </CardContent>

              <Divider />

              <CardContent>
                <Typography
                  className="orgNameTitle"
                  component="h3"
                >
                  Organization License Info
                </Typography>

                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  paddingTop={2}
                  paddingX={1.5}
                >
                  <SubStatusBadge onlyShowIfActionNeeded={false} />
                </Box>
              </CardContent>

              <Divider />

              <CardContent>
                <Flex justifyContent="space-between">
                  <Typography
                    className="orgNameTitle"
                    component="h3"
                  >
                    Organization Logo
                  </Typography>

                  {logoUrl && <OrganizationLogoMenuItems />}
                </Flex>

                <OrganizationLogoUploaderDialog />

                <OrganizationLogo withUploadButton />
              </CardContent>

              <Divider />

              <OrganizationCode
                code={code}
                orgName={orgName}
              />

              <Divider />

              <CardContent>
                <Typography
                  className="orgNameTitle"
                  component="h3"
                >
                  Organization School Year
                </Typography>

                <List>
                  <ListItem>
                    <ListItemText
                      primary={`${currentSchoolYearEnding - 1}-${currentSchoolYearEnding}`}
                    />
                  </ListItem>
                </List>
              </CardContent>

              {!isCollegeOrUniversity && (
                <>
                  <Divider />

                  <CardContent>
                    <Typography
                      className="orgNameTitle"
                      component="h3"
                    >
                      Successor Organization
                    </Typography>

                    <List>
                      <ListItem>
                        <ListItemText
                          primary={`${successorOrganization?.label ?? 'None selected'}`}
                          secondary="The organization your students will be in after graduating from your program."
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </>
              )}

              <Divider />

              <CardContent>
                <Flex justifyContent="space-between">
                  <Typography
                    className="orgNameTitle"
                    component="h3"
                  >
                    Communication Settings
                  </Typography>

                  <IconButton
                    aria-label="Edit Communication Settings"
                    onClick={handleNavigateToCommunicationSettings}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Flex>

                <List>
                  <ListItem>
                    <ListItemText
                      primary="CC directors on all emails"
                      secondary={organization.ccDirectors ? 'Yes' : 'No'}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary="Additional CC emails"
                      secondary={ccEmails?.length
                        ? ccEmails.map((ccEmail) => ccEmail.email).join('; ')
                        : '—'}
                    />
                  </ListItem>
                </List>
              </CardContent>

              <Divider />

              <CardContent>
                <Flex justifyContent="space-between">
                  <Typography
                    className="orgNameTitle"
                    component="h3"
                  >
                    Financial Settings
                  </Typography>

                  <IconButton
                    aria-label="Edit Financial Settings"
                    onClick={handleNavigateToFinancialSettings}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Flex>

                <List>
                  <ListItem>
                    <ListItemText
                      primary="Ignore financial data from previous school years?"
                      secondary={organization.isIgnoringPreviousYearsFinances ? 'Yes' : 'No'}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary="Scheduled Statements"
                      secondary={financialStatementScheduleTypeId
                        ? getScheduleTypeNameAndDescription(financialStatementScheduleTypeId)
                        : 'Off'}
                    />
                  </ListItem>

                  {financialStatementScheduleTypeId && (
                    <ListItem>
                      <ListItemText
                        primary="Send scheduled statements only to members with a balance?"
                        secondary={organization.financialStatementOnlyMembersWithBalance ? 'Yes' : 'No'}
                      />
                    </ListItem>
                  )}

                  <ListItem>
                    <ListItemText
                      primary="Send scheduled statements only to adult relatives?"
                      secondary={organization.financialStatementOnlySendToAdults ? 'Yes' : 'No'}
                    />
                  </ListItem>
                </List>
              </CardContent>

              <Divider />

              <CardContent>
                <Typography
                  className="orgNameTitle"
                  component="h3"
                >
                  Organization Actions
                </Typography>

                <List>
                  <SendWelcomeEmailListItem />

                  {canReadUsers && <ManageInactiveUsersListItem />}

                  {canBeginEndOfYearProcess && <BeginEndOfYearProcessListItem />}
                </List>
              </CardContent>
            </StyledEnhancedCard>
          );
        }}
      </Organization>

      <DialogEditOrganization />
    </>
  );
};

export default OrganizationCard;
